import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Icon from "../components/icons";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";

function AppScan() {
  const navigate = useNavigate();
  const [Cameraselected, setCameraSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data`, scanData);
    if (scanData != null && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
      var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
      switch (STATUS_ABSENSI) {
        case "CheckIn":
          console.log("Anda Sudah Absen Hari Ini");
          NotificationManager.info("Info message");
          setTimeout(() => {
            navigate("/Dashboard", { replace: true });
          }, 3000);
          break;
        case "Completed":
          console.log("Anda Sudah Logout Hari Ini");
          break;
        default:
          var body = {
            iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
          };
          axios({
            method: "post",
            url: scanData,
            data: body,
          })
            .then(function (res) {
              if (res.data.Data != "iduser_absensi_kosong") {
                var idabsesi = res.data.Data["IDABSENSI"];
                var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
                sessionStorage.setItem("IDABSENSI", idabsesi);
                sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
                console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
                navigate("/Dashboard", { replace: true });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          break;
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const AbsenLogin = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "CheckIn":
        console.log("Anda Sudah Absen Hari Ini");
        navigate("/Dashboard", { replace: true });
        break;
      case "Completed":
        console.log("Anda Sudah Logout Hari Ini");
        break;
      default:
        var body = {
          iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
        };
        axios({
          method: "post",
          url: "http://apiabsensi.whusnet.com/api/Absensi/checkIn",
          data: body,
        })
          .then(function (res) {
            if (res.data.Data != "iduser_absensi_kosong") {
              var idabsesi = res.data.Data["IDABSENSI"];
              var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
              sessionStorage.setItem("IDABSENSI", idabsesi);
              sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  const AbsenLogOut = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "Belum_Absen":
        console.log("Anda Belum Absen Hari Ini");
        break;
      case "Completed":
        console.log("Anda Sudah Logout Hari Ini");
        break;
      default:
        var body = {
          idabsensi: sessionStorage.getItem("IDABSENSI", true),
        };
        axios({
          method: "post",
          url: "http://apiabsensi.whusnet.com/api/Absensi/checkOut",
          data: body,
        })
          .then(function (res) {
            console.log(res);
            if (res.data.Data != "idabsensi_kosong") {
              sessionStorage.setItem("STATUS_ABSENSI", "Completed");
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };
  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    acceptPolicy();
  }, []);

  const SelectFrontCamera = () => {
    setCameraSelected("user");
    console.log("sekarang menggunakan kamera depan");
  };
  const SelectBackCamera = () => {
    setCameraSelected("environment");
    console.log("sekarang menggunakan kamera belakang");
  };

  if (Cameraselected == "user") {
    var buttonRotateCamera = (
      <button
        variant="outline-light"
        onClick={SelectBackCamera}
        className="custombutton"
      >
        <Icon.RotateCamera />
      </button>
    );
  } else {
    var buttonRotateCamera = (
      <button
        variant="outline-light"
        onClick={SelectFrontCamera}
        className="custombutton"
      >
        <Icon.RotateCamera />
      </button>
    );
  }

  return (
    <>
      <div
        id="navbar"
        className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom"
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("Dashboard")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ScanQr")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ProfilSaya")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar className="bg-default rounded-bottom">
              <Container fluid>
                <Navbar.Brand className="text-white">
                  <a onClick={(key) => Navigate("Dashboard")}>
                    <Icon.BackIcon />
                  </a>
                  <span className="ms-3">Scan Qr Code Abbsen</span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end text-white">
                  <Navbar.Text>
                    <Icon.ArrowsSort />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div data-cy="card-info" className="col-12 px-0 mt-0">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div className="mt-5">
                <QrReader
                  className="tescssqr"
                  facingMode={Cameraselected}
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
                  // chooseDeviceId={()=>selected}
                  style={{ width: "300px" }}
                />
              </div>
            </div>
            <div className="text-center mt-2">
              {buttonRotateCamera}
              {/* <button onClick={AbsenLogin} className="btn btn-primary">
                Absen
              </button>
              <button onClick={AbsenLogOut} className="btn btn-primary">
                Check Out
              </button> */}
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AppScan