import React, { useEffect, useState, Component } from 'react';
import {
  Button, Form, FloatingLabel, Container,
  Nav, Navbar, NavDropdown,
  Tab, Tabs, Toast
} from 'react-bootstrap';
import helpers from '../components/helpers';
import Images from '../components/images';
import Icon from '../components/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
////////////////////////////////////////////////////////////////
import { useNavigate } from "react-router-dom";
// import dataRiwayat from '../assets/dataRiwayat';


function App() {
  const navigate = useNavigate();
  const Navigate = (key) => { navigate("/" + key, { replace: true }); }
  const [dataPenggunaLogin, setdataPenggunaLogin] = useState({
    IDCABANG: "",
    IDUSER_ABSENSI: "",
    IDUSER: "",
    EMAIL: "",
    NAMALENGKAP: "",
    NAMA_PERUSAHAAN: "",
    HP: "",
    ALAMAT: "",
    JABATAN: "",
  })
  const [STATUS_ABSENSI, setSTATUS_ABSENSI] = useState('')
  const [Reload, setReload] = useState('')
  const [datefrom, setdatefrom] = useState('')
  const [dateto, setdateto] = useState('')
  const [dataRiwayat, setdataRiwayat] = useState([])
  // distance param
  const [radius, setRadius] = useState('');
  const [error, setError] = useState(null);
  const [ShowAlert, setShowAlert] = useState(true);


  const getRiwayat = () => {
    var url = "http://apiabsensi.whusnet.com/api/Riwayat/riwayat";
    var param = {
      iduser: sessionStorage.getItem('IDUSER_ABSENSI', true),
      datefrom: datefrom,
      dateto: dateto
    }
    axios.get(url, {
      params: param
    })
      .then(res => {
        setdataRiwayat(res.data.Data)
      })
      .catch(error => {
        console.log("error");
      });
  }

  const getDataPengguna = () => {
    let IDCABANG = sessionStorage.getItem('IDCABANG', true);
    let IDUSER_ABSENSI = sessionStorage.getItem('IDUSER_ABSENSI', true);
    let IDUSER = sessionStorage.getItem('IDUSER', true);
    let EMAIL = sessionStorage.getItem('EMAIL', true);
    let NAMALENGKAP = sessionStorage.getItem('NAMALENGKAP', true);
    let NAMA_PERUSAHAAN = sessionStorage.getItem('NAMA_PERUSAHAAN', true);
    let HP = sessionStorage.getItem('HP', true);
    let ALAMAT = sessionStorage.getItem('ALAMAT', true);
    let JABATAN = sessionStorage.getItem('JABATAN', true);
    setdataPenggunaLogin({
      ...dataPenggunaLogin,
      IDCABANG: IDCABANG,
      IDUSER_ABSENSI: IDUSER_ABSENSI,
      IDUSER: IDUSER,
      EMAIL: EMAIL,
      NAMALENGKAP: NAMALENGKAP,
      NAMA_PERUSAHAAN: NAMA_PERUSAHAAN,
      HP: HP,
      ALAMAT: ALAMAT,
      JABATAN: JABATAN,
    });
  };


  const CekDataCheckInToday = () => {
    var url = "http://apiabsensi.whusnet.com/api/Absensi/CekDataCheckInToday";
    var param = {
      iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        if (res.data.Data != "Belum Absen Hari Ini") {
          var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
          var IDABSENSI = res.data.Data["IDABSENSI"];
          setSTATUS_ABSENSI(STATUS_ABSENSI);
          sessionStorage.setItem("IDABSENSI", IDABSENSI);
          sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
          setReload(STATUS_ABSENSI);
        } else {
          sessionStorage.setItem("STATUS_ABSENSI", "Belum_Absen");
        }
        console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const AbsenLogin = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "CheckIn":
        console.log("Anda Sudah Absen Hari Ini");
        break;
      case "Panding":
        console.log("Anda Sudah Absen Hari Ini");
        break;
      case "Completed":
        console.log("Anda Sudah Logout Hari Ini");
        break;
      default:
        var body = {
          iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
        };
        axios({
          method: "post",
          url: "http://apiabsensi.whusnet.com/api/Absensi/checkInPanding",
          data: body,
        })
          .then(function (res) {
            if (res.data.Data != "iduser_absensi_kosong") {
              var idabsesi = res.data.Data["IDABSENSI"];
              var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
              setSTATUS_ABSENSI(STATUS_ABSENSI);
              sessionStorage.setItem("IDABSENSI", idabsesi);
              sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
              setReload(STATUS_ABSENSI);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  const AbsenLogOut = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "Belum_Absen":
        console.log("Anda Belum Absen Hari Ini");
        break;
      case "Panding":
        console.log("Anda Sudah Absen Hari Ini");
        break;
      case "Completed":
        console.log("Anda Sudah Logout Hari Ini");
        break;
      default:
        var body = {
          idabsensi: sessionStorage.getItem("IDABSENSI", true),
        };
        axios({
          method: "post",
          url: "http://apiabsensi.whusnet.com/api/Absensi/checkOut",
          data: body,
        })
          .then(function (res) {
            console.log(res);
            if (res.data.Data != "idabsensi_kosong") {
              setSTATUS_ABSENSI("Completed");
              sessionStorage.setItem("STATUS_ABSENSI", "Completed");
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
              setReload(STATUS_ABSENSI);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  // distance function
  const calculateDistance = () => {
    // coordinate user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          var url = `http://apiabsensi.whusnet.com/api/Absensi/GetDataLatLongCabang`;
          var iduser_absensi = sessionStorage.getItem("IDUSER_ABSENSI", true);
          const res = axios.get(url, { params: { iduser_absensi } })
            .then(function (res) {
              const centerLatitude = res.data.Data['LAT_PERUSAHAAN'];
              const centerLongitude = res.data.Data['LONG_PERUSAHAAN'];
              console.log(centerLatitude)
              console.log(centerLongitude)
              // const centerLatitude = -7.8561949; // Example latitude
              // const centerLongitude = 111.490201; // Example longitude
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              // Coordinates of the center point
              // Radius in meters
              const radiusInMeters = 1000; // Example radius in meters
              // Convert degrees to radians
              const deg2rad = deg => deg * (Math.PI / 180);
              // Calculate the distance using Haversine formula
              const distance = (lat1, lon1, lat2, lon2) => {
                const earthRadius = 6371e3; // Earth's radius in meters
                const dLat = deg2rad(lat2 - lat1);
                const dLon = deg2rad(lon2 - lon1);
                const a =
                  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(deg2rad(lat1)) *
                  Math.cos(deg2rad(lat2)) *
                  Math.sin(dLon / 2) *
                  Math.sin(dLon / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                return earthRadius * c;
              };
              // Calculate the distance between the center point and the input coordinates
              const dist = distance(centerLatitude, centerLongitude, latitude, longitude);
              // Check if the distance is within the radius
              if (dist > radiusInMeters) {
                setRadius('Outside');
                console.log("Outside distance")
              } else {
                setRadius('Inside');
                console.log("Insite distance")
              }
            })
            .catch(function (error) {
              // console.log(error);
            });
        },
        error => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }

  // function acceptPolicy
  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem('Status', true);
      let message = sessionStorage.getItem('Message', true);
      if (status != 200) { navigate("/", { replace: true }); }
    }
    catch (err) {
      let Message = sessionStorage.getItem('Message', true);
      sessionStorage.setItem('Message', Message);
      navigate("/", { replace: true })
    }
  }

  useEffect(() => {
    acceptPolicy();
    getDataPengguna();
    CekDataCheckInToday();
    getRiwayat();
    calculateDistance();
  }, []);
  useEffect(() => {
    getRiwayat();
  }, [Reload]);


  if (radius == "Outside") {
    if (STATUS_ABSENSI == "CheckIn") {
      var IconNavbar = <Icon.IndicatorOpen />
      var TombolAbsen = <Button onClick={AbsenLogOut} variant="outline-light rounded-3 py-2 mt-2" size="sm">
        Check Out
      </Button>
    } else {
      var IconNavbar = <Icon.IndicatorClose />
      var TombolAbsen = <Button onClick={AbsenLogin} variant="outline-light rounded-3 py-2 mt-2" size="sm">
        Absen Now
      </Button>
    }
  } else {
    if (STATUS_ABSENSI == "CheckIn") {
      var IconNavbar = <Icon.IndicatorOpen />
      var TombolAbsen = ""
    } else {
      var IconNavbar = <Icon.IndicatorClose />
      var TombolAbsen = ""
    }
  }
  return (
    <>
      <div id="navbar" className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom">
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a onClick={(key) => Navigate('Dashboard')} className="text-center border-0 bg-light list-group-item list-group-item-action active" aria-current="true">
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a onClick={(key) => Navigate('ScanQr')} className="text-center border-0 bg-light list-group-item list-group-item-action active" aria-current="true">
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a onClick={(key) => Navigate('ProfilSaya')} className="text-center border-0 bg-light list-group-item list-group-item-action active" aria-current="true">
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>

        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar>
              <Container fluid>
                <Navbar.Brand href="#home">
                  {/* <img alt=""
                    src="/logo.svg"
                    width="30" height="30"
                    className="d-inline-block align-top" />  */}
                </Navbar.Brand>
                <Navbar.Brand href="#home">Aplikasi Absensi</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    {IconNavbar}
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div className="col-12 bg-white ps-2">
            <h4 className="pt-3 ps-2">Hello, {dataPenggunaLogin.NAMALENGKAP}. Selamat Bekerja!</h4>
          </div>

          <div data-cy="card-info" className="col-12 px-0 mt-2">
            <div className="bg-default bg-gradient rounded-top shadow-sm p-3 py-3 mx-2 mb-0">

              <div class="d-flex align-items-center bd-highlight">
                <div class="p-2 w-100 bd-highlight">
                  <div className="col-12 text-white">
                    <div className="">{dataPenggunaLogin.EMAIL}</div>
                  </div>
                  <hr className="text-white my-1" />
                  <div className="col-12">
                    <div className="text-white-50">{dataPenggunaLogin.JABATAN}</div>
                  </div>
                </div>
                <div class="p-2 flex-shrink-1 bd-highlight">
                  <div class="text-center">
                    <div class="mb-2 mx-4">
                      <a onClick={(key) => Navigate('ScanQr')}>
                        <Icon.QrCode />
                      </a>
                    </div>
                    {TombolAbsen}
                  </div>
                </div>
              </div>

            </div>


            <div className="bg-gray bg-gradient rounded-bottom shadow-sm p-3 mx-2 mb-2">
              <div className="row gx-1">
                <div className="col text-white">
                  <div className="text-black-50">Id : {dataPenggunaLogin.IDUSER}</div>
                </div>
                <div className="col align-items-end text-end">
                  <div className="text-black-50">No. {dataPenggunaLogin.HP}</div>
                </div>
              </div>
            </div>

            <div data-cy="section-riwayat-Absensi" className="bg-white bg-gradient p-3 mx-2 mb-2">
              <div className="row gx-3">
                <div className="col-9 ps-1 text-dark">
                  <p className="text-dark lt-spacing">Riwayat Absensi</p>
                </div>
                <div className="col-3 align-items-end text-end">
                  <a className="text-decoration-none" onClick={(key) => Navigate('RiwayatAbsensi')}>Detail</a>
                </div>
              </div>


              <div className="row">
                <div className="col list-group pe-0">

                  {
                    dataRiwayat.map((item, i) => {
                      if (i + 1 <= 3) {
                        if (item.CHECKOUT_AT_HOUR != null) {
                          var HourItem = item.CHECKOUT_AT_HOUR;
                          var DateItem = item.CHECKOUT_AT_DATE;
                        } else {
                          var HourItem = item.CHECKIN_AT_HOUR;
                          var DateItem = item.CHECKIN_AT_DATE;
                        }
                        return (
                          <div className="card border-0 shadow-sm list-group-item-action active mb-2">
                            <div className="card-body">
                              <div className="row gx-3">
                                <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                                  <h6 className="card-title text-default fw-600">{item.STATUS_ABSENSI}</h6>
                                </div>
                                <div className="col-3 align-items-end text-end">
                                  <h6 className="card-title">{HourItem}</h6>
                                </div>
                              </div>
                              <div className="row gx-3">
                                <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                                  <p className="text-dark lt-spacing mb-0">{DateItem}</p>
                                </div>
                                <div className="col-3 align-items-end text-end pe-0">
                                  <Icon.checkIcon className="text-red" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return "";
                      }
                    })
                  }
                  {/* <div className="card border-0 shadow-sm list-group-item-action active mb-2">
                    <div className="card-body">
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <h6 className="card-title text-default fw-600">Checkin</h6>
                        </div>
                        <div className="col-3 align-items-end text-end">
                          <h6 className="card-title">07:21:11</h6>
                        </div>
                      </div>
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <p className="text-dark lt-spacing mb-0">Selasa,10 sep 2022</p>
                        </div>
                        <div className="col-3 align-items-end text-end pe-0">
                          <Icon.checkIcon className="text-red" />
                        </div>
                      </div>
                    </div>
                  </div> */}



                </div>
              </div>



            </div>


          </div>


        </div>
      </div>
    </>
  );

}

export default App;
