import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
// import swal from 'sweetalert';

// import DataTable from 'react-data-table-component';
import helpers from "../components/helpers";
import Images from "../components/images";
import Icon from "../components/icons";
// import dataRiwayat from "../assets/dataRiwayat";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function App() {
  const navigate = useNavigate();

  const [dataModel, setDataModel] = useState([]);
  const [mode, setMode] = useState("form");
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [processing, modeProcessing] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [title, setTitle] = useState("List data Pengguna");
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [iduser, setiduser] = useState("12345.01");
  const [datefrom, setdatefrom] = useState("");
  const [dateto, setdateto] = useState("");
  const [dataRiwayat, setdataRiwayat] = useState([]);

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };

  const getRiwayat = () => {
    var url = "http://apiabsensi.whusnet.com/api/Riwayat/riwayat";
    var param = {
      iduser: sessionStorage.getItem("IDUSER_ABSENSI", true),
      datefrom: datefrom,
      dateto: dateto,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        setdataRiwayat(res.data.Data);
        console.log(res);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    acceptPolicy();
    getRiwayat();
  }, []);

  return (
    <>
      <div
        id="navbar"
        className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom"
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("Dashboard")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ScanQr")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ProfilSaya")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar className="bg-default rounded-bottom">
              <Container fluid>
                <Navbar.Brand className="text-white">
                  <a onClick={(key) => Navigate("Dashboard")}>
                    <Icon.BackIcon />
                  </a>
                  <span className="ms-3">Riwayat Absensi</span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end text-white">
                  <Navbar.Text>
                    <Icon.ArrowsSort />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <div data-cy="card-info" className="col-12 px-0 mt-0">
            <div
              data-cy="section-riwayat-Absensi"
              className="bg-white bg-gradient p-3 mx-2 mb-2"
            >
              {dataRiwayat.map((item) => {
                if (item.CHECKOUT_AT_HOUR != null) {
                  var HourItem = item.CHECKOUT_AT_HOUR;
                  var DateItem = item.CHECKOUT_AT_DATE;
                } else {
                  var HourItem = item.CHECKIN_AT_HOUR;
                  var DateItem = item.CHECKIN_AT_DATE;
                }
                return (
                  <div className="card border-0 shadow-sm list-group-item-action active mb-2">
                    <div className="card-body">
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <h6 className="card-title text-default fw-600">
                            {item.STATUS_ABSENSI}
                          </h6>
                        </div>
                        <div className="col-3 align-items-end text-end">
                          <h6 className="card-title">{HourItem}</h6>
                        </div>
                      </div>
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <p className="text-dark lt-spacing mb-0">
                            {DateItem}
                          </p>
                        </div>
                        <div className="col-3 align-items-end text-end pe-0">
                          <Icon.checkIcon className="text-red" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
