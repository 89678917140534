import React, { useEffect, useState } from "react";
import { Button, Form, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import dataCabang from "../assets/dataCabang";

function App() {
  const [dataModel, setDataModel] = useState([]);
  const [mode, setMode] = useState("form");
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [processing, modeProcessing] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [title, setTitle] = useState("List data Pengguna");
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [DataForm, setDataForm] = useState({
    NAMALENGKAP: "",
    EMAIL: "",
    PASS: "",
  });

  function setData(e) {
    setDataForm({
      ...DataForm,
      [e.target.name]: e.target.value,
    });
    console.log(DataForm);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 px-0">
          <div className="bg-default bg-gradient rounded-bottom p-4 py-5">
            <p className="h5 text-center text-white py-5 lt-spacing">
              Aplikasi Absensi
            </p>
          </div>
        </div>

        <div
          data-cy="form-login"
          className="col-12 px-0 z-index-1 position-absolute mt-11"
        >
          <div className="bg-white bg-gradient rounded-4 shadow p-3 py-3 mx-4 mb-4">
            <p className="h6 text-center text-default py-4 mt-0 lt-spacing">
              Buat Akun
            </p>

            <FloatingLabel controlId="floatingSelect" label="Dari Cabang ?">
              <Form.Select aria-label="Floating label select example">
                {dataCabang.map((item) => {
                  return <option value={item.id}>{item.cabang}</option>;
                })}
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              className="mt-4"
              controlId="floatingInputGrid"
              label="Nama"
            >
              <Form.Control
                type="text"
                placeholder="Andiana"
                value={DataForm.NAMALENGKAP}
                onChange={setData}
                name={"NAMALENGKAP"}
              />
            </FloatingLabel>

            <FloatingLabel
              className="mt-4"
              controlId="floatingInputGrid"
              label="Email address"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={DataForm.EMAIL}
                onChange={setData}
                name={"EMAIL"}
              />
            </FloatingLabel>

            <FloatingLabel
              className="mt-4"
              controlId="floatingInputGrid"
              label="Password"
            >
              <Form.Control
                type="password"
                placeholder="1234"
                value={DataForm.PASS}
                onChange={setData}
                name={"PASS"}
              />
            </FloatingLabel>
            <Form.Text id="passwordHelpBlock" muted>
              Password harus 8-20 panjangnya , kombinasi huruf Besar Dan Angka.
            </Form.Text>

            <div className="text-center mt-4 d-grid gap-2">
              <Button variant="default text-white rounded-3 py-3" size="sm">
                Buat Akun
              </Button>
            </div>
            <div className="mt-0 d-grid gap-2 fs-6">
              <a
                href="#"
                className="fst-italic text-decoration-none link-warning mb-2"
              >
                Kembali login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
